<template>
    <properties-block  v-bind="$props" v-on="$listeners">
        <sidebar-group title="Embed Website|Site intégré">
            <b-form-group>
                <template #label>
                    <btn-language-prop class="float-right" v-model="block.iframe" field="src"/>
                    <span v-tr>URL|URL</span>
                </template>
                <input-url title="Iframe|Site intégré" v-model="block.iframe.languageProxy.src"
                           placeholder="Replace Iframe|Remplacer le lien" variant="light-primary" block @edit="iframeModalVisible = true"/>
            </b-form-group>
            <b-form-group>
                <input-border-radius v-model="block.iframe.borderRadius"/>
            </b-form-group>
            <b-form-group label-cols="8" :label="'Height|Hauteur'|tr">
                <e-input type="number" v-model.number="block.iframe.height" :min="20" :max="2000" placeholder="Auto" state="disabled"/>
                <span class="unit">px</span>
            </b-form-group>
        </sidebar-group>

        <b-modal v-model="iframeModalVisible" :title="'Website URL|Lien du site internet'|tr" size="lg">
            <b-form-group :label="'Website URL|URL du site internet'|tr">
                <e-input type="url" v-model="iframeUrl" placeholder="Ex : https://eye-in.com"/>
                <div class="form-help mt-2" v-tr>
                    Make sure the website allows iframe|Vérifiez que le site internet supporte les "iframes"
                </div>
            </b-form-group>

            <template #modal-footer>
                <b-btn variant="secondary" @click="iframeModalVisible = false" v-tr>Cancel|Annuler</b-btn>
                <b-btn variant="primary" :disabled="!iframeUrl" @click="apply">
                    <span v-tr>Select|Appliquer</span>
                </b-btn>
            </template>
        </b-modal>
    </properties-block>
</template>

<script>
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";
import BtnLanguageProp from "@/components/editor/properties/components/btn-language-prop.vue";
import InputBorderRadius from "@/components/editor/properties/inputs/input-border-radius.vue";
import InputLink from "@/components/editor/properties/inputs/input-link.vue";
import InputUrl from "@/components/editor/properties/inputs/input-url.vue";
import EInput from "../../../../../vue-components/components/e-input.vue";
import Validation from "../../../../../vue-components/helpers/Validation.js";

// properties-block-iframe #hot-reload-debug
export default {
    name: `properties-block-iframe`,
    components: {BtnLanguageProp, InputUrl, InputBorderRadius, InputLink, EInput, PropertiesBlock},
    extends: PropertiesBlock,
    data() {
        return {
            iframeUrl: ``,
            iframeModalVisible: false
        }
    },
    mounted() {
        if (!this.block.iframe.src) {
            this.iframeModalVisible = true;
        } else {
            this.iframeUrl = this.block.iframe.languageProxy.src;
        }
    },
    methods: {
        apply() {
            this.block.iframe.languageProxy.src = this.iframeUrl;
            this.block.setValidation(Validation.testURL(this.iframeUrl));
            this.iframeModalVisible = false;
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
